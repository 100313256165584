// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-templates-category-category-loadmore-js": () => import("./../../../src/templates/category/category-loadmore.js" /* webpackChunkName: "component---src-templates-category-category-loadmore-js" */),
  "component---src-templates-games-game-js": () => import("./../../../src/templates/games/game.js" /* webpackChunkName: "component---src-templates-games-game-js" */),
  "component---src-templates-page-activation-user-js": () => import("./../../../src/templates/page/activation-user.js" /* webpackChunkName: "component---src-templates-page-activation-user-js" */),
  "component---src-templates-page-additional-info-page-js": () => import("./../../../src/templates/page/additionalInfoPage.js" /* webpackChunkName: "component---src-templates-page-additional-info-page-js" */),
  "component---src-templates-page-casino-hotels-js": () => import("./../../../src/templates/page/casinoHotels.js" /* webpackChunkName: "component---src-templates-page-casino-hotels-js" */),
  "component---src-templates-page-casino-online-js": () => import("./../../../src/templates/page/casinoOnline.js" /* webpackChunkName: "component---src-templates-page-casino-online-js" */),
  "component---src-templates-page-default-js": () => import("./../../../src/templates/page/default.js" /* webpackChunkName: "component---src-templates-page-default-js" */),
  "component---src-templates-page-download-js": () => import("./../../../src/templates/page/download.js" /* webpackChunkName: "component---src-templates-page-download-js" */),
  "component---src-templates-page-faq-js": () => import("./../../../src/templates/page/faq.js" /* webpackChunkName: "component---src-templates-page-faq-js" */),
  "component---src-templates-page-promotions-js": () => import("./../../../src/templates/page/promotions.js" /* webpackChunkName: "component---src-templates-page-promotions-js" */),
  "component---src-templates-page-register-js": () => import("./../../../src/templates/page/register.js" /* webpackChunkName: "component---src-templates-page-register-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-redirect-301-js": () => import("./../../../src/templates/redirect-301.js" /* webpackChunkName: "component---src-templates-redirect-301-js" */),
  "component---src-templates-search-js": () => import("./../../../src/templates/search.js" /* webpackChunkName: "component---src-templates-search-js" */)
}

